<template>
 <div class="work-order-gather">
   <van-nav-bar
       left-arrow
       left-text="返回"
       @click-left="goBack"
       :title="moduleTitle"
   />
<!--   <van-radio-group v-model="selDateType" direction="horizontal" class="date-radio">-->
<!--     <van-radio name="1">七天</van-radio>-->
<!--     <van-radio name="2">近一月</van-radio>-->
<!--     <van-radio name="3">自定义</van-radio>-->
<!--     <van-button type="primary" v-show="selDateType !== '3'" @click="onConfirm">查询</van-button>-->
<!--   </van-radio-group>-->
<!--   <van-cell-group class="date-cell-group" v-show="selDateType === '3'">-->
<!--     <van-cell title="选择日期区间" :value="selDate" @click="show = true" is-link/>-->
<!--     <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :allow-same-day="true"/>-->
<!--   </van-cell-group>-->

   <div id="drawTotalDivI" style="width: 100%;height:400px;" class="draw-div" v-if="totalDiv"></div>
   <div id="drawTotalDivR" style="width: 100%;height:400px;" class="draw-div" v-if="totalDiv"></div>
   <template v-for="drawDiv in drawDivs">
     <div :id="'drawDivI' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivR' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
   </template>
 </div>
</template>

<script>

export default {
  name: "Analysis1004",
  data() {
    return {
      moduleTitle: '',
      show: false,
      miniReqParams: {
        //0=今天，1=时间区间
        timeConditionType: '0',
        startDate: '',
        endDate: '',
        month: ''
      },
      drawDivs: [],
      existCharts: [],
      totalDiv: false
    }
  },
  methods:{
    goBack() {
      this.$router.back();
    },
    // onConfirm(date) {
    //   if(this.selDateType === '3') {
    //     const [start, end] = date;
    //     this.show = false;
    //     this.miniReqParams.timeConditionType = '1'
    //     this.miniReqParams.startDate = this.formatDate(start);
    //     this.miniReqParams.endDate = this.formatDate(end);
    //   } else {
    //     if(this.selDateType === '1') {
    //       this.miniReqParams.startDate = this.dateUtils.getBeforeDate(7);
    //       this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
    //     } else {
    //       this.miniReqParams.startDate = this.dateUtils.getCurrentMonthFirstDay();
    //       this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
    //     }
    //   }
    //   this.$toast.loading({message: '请求中', overlay: true});
    //   this.sendQuery();
    // },
    sendQuery() {
      //发送查询请求
      this.postRequest('/wechat-analysis/cd-work-order-gather', this.miniReqParams).then(resp => {
        // console.log(resp);
        if(resp) {
          if(this.existCharts.length > 0) {
            this.existCharts.forEach(charts => {
              charts.dispose();
            })
            this.existCharts = [];
          }
          let data = resp.data;
          if(resp.attachObj && resp.attachObj.length > 0) {
            this.totalDiv = true;
            this.$nextTick(() => {
              let totalMap = [{"合计数": resp.attachObj}]
              this.drawCharts(totalMap, true);
            });
          }
          if(data && data.length > 0) {
            this.drawDivs = Object.keys(data);
            this.$nextTick(() => {
              this.drawCharts(data, false);
            })
          } else {
            this.$toast("查询无数据，请换条件查询！")
          }
        }
      })
    },
    drawCharts(data, type) {
      let titles = ['安装工单','维修工单']
      let legendGroup = [['新增安装工单数','新增安装设备台数','当天安装完工单数','当天安装完工设备台数'],['新增维修工单数','新增维修设备台','当天维修完工单数','当天维修完工设备台数']];
      let columnsGroup = [['cut','machineNum','finishCut','finishMachineNum2'],['cutRepair','machineNumRepair','finishCutRepair','finishMachineNumRepair']];
      //数据组装
      data.forEach((company, index) => {
        let key = Object.keys(company);
        let items = company[key];
        legendGroup.forEach((legend, i) => {
          let params = {};
          //标题
          params.title = type ? '广佛' + titles[i] + ' ' + key : key + ' ' + titles[i];
          //线条名称
          params.legend = legend;
          params.columns = columnsGroup[i];
          //日期
          params.showDates = [];
          //展示的内容
          params.series = [];
          params.columns.forEach((column, columnIndex) => {
            let columnData = [];
            items.forEach(item => {
              columnData.push(item[column]);
              if(columnIndex == 0) {
                params.showDates.push(item.udate.substring(8))
              }
            })
            let obj =  {
              name: params.legend[columnIndex],
              type: 'bar',
              stack: column,
              data: columnData
            }
            params.series.push(obj);
          })
          if(type) {
            if(i == 0) {
              this.myEcharts('drawTotalDivI', params);
            } else {
              this.myEcharts('drawTotalDivR', params);
            }
          } else {
            if(i == 0) {
              this.myEcharts('drawDivI' + index, params);
            } else {
              this.myEcharts('drawDivR' + index, params);
            }
          }
        });
      })
    },
    myEcharts(id, params){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));
      let app = {};
      let posList = [
        'left', 'right', 'top', 'bottom',
        'inside',
        'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
        'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      };

      app.config = {
        rotate: 0,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideLeft',
        distance: 15,
        onChange: function () {
          let labelOption = {
            normal: {
              rotate: app.config.rotate,
              align: app.config.align,
              verticalAlign: app.config.verticalAlign,
              position: app.config.position,
              distance: app.config.distance
            }
          };
          myChart.setOption({
            series: [{
              label: labelOption
            }, {
              label: labelOption
            }, {
              label: labelOption
            }, {
              label: labelOption
            }]
          });
        }
      };

      let labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {
          }
        }
      };
      params.series.forEach(value => {
        value.label = labelOption;
      })
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: params.title
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: true,
          top: '6%',
          data: params.legend
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '11%',
          bottom: '1%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          boundaryGap: false,
          data: params.showDates
        },
        xAxis: [{
          type: 'value',
          axisTick: {
            inside: true
          },
          scale: true,
          axisLabel: {
            margin: 2,
            formatter: function (value, index) {　　
              //y轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + "万";
              }
              return value;
            }
          },
          "splitLine": {//网格线 默认true
            "show": true
          }
        }],
        series: params.series
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    },
  },
  computed: {
    // selDate() {
    //   if(this.miniReqParams.startDate.length > 0
    //       && this.miniReqParams.endDate.length > 0) {
    //     return `${this.miniReqParams.startDate}-${this.miniReqParams.endDate}`
    //   }
    // }
  },
  mounted: function () {
    this.sendQuery();
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  }
}
</script>

<style scoped>
.date-radio {
  margin-top: 15px;
  background-color: #fff;
  padding: 10px 16px;
}
.date-radio>button {
  margin-left: auto;
}
.draw-div {
  margin-top: 15px;
  background-color: white;
}
.van-nav-bar__content {
  margin-bottom: 10px;
}
.work-order-gather {
  padding-bottom: 15px;
  width: 100%;
}
.date-cell-group {
  margin: 15px 0;
}
.van-cell__value {
  color: #999;
}
</style>